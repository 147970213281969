import React, {Component, FormEvent, FormEventHandler, KeyboardEvent} from 'react';
import './App.css';
import words from './words_500.json'

class Properties {
}


enum LetterState {
    unsubmitted,
    notPresent,
    present,
    correct
}

const mapping = new Map<LetterState, string>([
    [LetterState.unsubmitted, 'unsubmitted'],
    [LetterState.notPresent, 'notpresent'],
    [LetterState.present, 'present'],
    [LetterState.correct, 'correct']]
)

function colourMapping(state: State, letterState: LetterState, wordIdx: number, letterIdx: number): string {
    let result = mapping.get(letterState);
    result = result || 'unsubmitted'
    if (state.current.word === wordIdx && state.current.letter === letterIdx) {
        result = "current"
    }
    return result;

}


class Letter {
    state: LetterState = LetterState.unsubmitted;
    letter: string = ''
}

class Word {
    letters: Letter[] = Array.from(Array(5).keys())
        .map(idx => new Letter());
    done: boolean = false
}

function getTarget(): string {
    return words[Math.floor(Math.random() * words.length)];
}

class Position {
    word: number = 0;
    letter: number = 0;
}

class State {
    words: Word[] = Array.from(Array(6).keys()).map(idx => new Word())
    target: string = getTarget()
    current = new Position()
    gameState: string = 'playing';
    debug: string = ""
}


class Table extends Component<Properties, State> {

    constructor(props: Properties) {
        super(props);
        this.state = new State();
    }

    focusCurrent() {
        //let ref: HTMLInputElement | null | undefined = this.inputRefs[this.state.current.word][this.state.current.letter];
        //ref && ref.focus();
    }
    componentDidMount() {
        console.log("Component did mount.")
        this.focusCurrent();
    }

    onInput(state: State) {
        return (event: FormEvent<HTMLInputElement>) => {
            event.preventDefault();
            event.stopPropagation();
            let inputLength = event.currentTarget.value.length
            let input = inputLength > 0 ? event.currentTarget.value.charAt(inputLength - 1) : ''
            console.log(input)

            let newState: State = {...(state)}
            newState.debug = input
            input = input.toLowerCase()

            if ('abcdefghijklmnopqrstuvwxyz'.includes(input)) {

                if (this.state.words[this.state.current.word].done) {
                    newState.current.word = Math.min(5, this.state.current.word + 1)
                    newState.current.letter=0
                } else {
                    newState.words[this.state.current.word].letters[this.state.current.letter].letter = input

                    if (this.state.current.letter === 4) {
                        newState.words[this.state.current.word].done = true
                        let correct: number = 0
                        for (let i = 0; i < 5; i++) {
                            let newLetter : string = newState.words[this.state.current.word].letters[i].letter
                            if (this.state.target.charAt(i) === newLetter) {
                                newState.words[this.state.current.word].letters[i].state = LetterState.correct
                                correct++;
                            } else if (this.state.target.includes(newLetter)) {
                                newState.words[this.state.current.word].letters[i].state = LetterState.present
                            } else {
                                newState.words[this.state.current.word].letters[i].state = LetterState.notPresent
                            }
                        }

                        if (this.state.current.word === 5) {
                            newState.current.word = -1;
                            newState.current.letter = -1
                            newState.gameState = 'lost'
                        } else {
                            newState.current.word = Math.min(5, this.state.current.word + 1)
                            newState.current.letter = 0;
                        }

                        if (correct === 5){
                            newState.gameState='won'
                        }
                    }else {
                        newState.current.letter = Math.min(4, this.state.current.letter + 1)
                    }

                }

            }
            this.setState(() => newState)
            this.focusCurrent();
        }

    }

    winBanner() {
        if (this.state.gameState === 'won') {
            return (<p onClick={() => this.setState(() => new State())}>YOU WIN! Again?</p>)
        } else if (this.state.gameState === 'lost') {
            return (<p onClick={() => this.setState(() => new State())}>You lose. {this.state.target}</p>)
        } else {
            return (<p onClick={() => this.setState(() => new State())}>New game</p>)
        }
    }

    render() {
        return (<div>

            <table>
                <tbody>
                {this.renderTableRows(this.state)}
                </tbody>
            </table>
            <div className="win">
                {this.winBanner()}
            </div>
        </div>);
    }

    private renderWord(state: State, word: Word, wordIdx: number) {
        return word.letters.map((letter: Letter, idx: number) => (
            <td key={idx}>
                <input
                    autoFocus={true}
                    onFocus={this.focusCurrent}
                    onClick={this.focusCurrent}
                    onInput={this.onInput(this.state)}
                    className={"letter " + colourMapping(state, letter.state, wordIdx, idx)}
                    value={letter.letter}
                    tabIndex={wordIdx * 5 + idx}
                />

            </td>
        ))
    }

    private renderTableRows(state: State) {
        return this.state.words.map((word: Word, idx: number) => (
            <tr key={idx}>
                {this.renderWord(state, word, idx)}
            </tr>)
        );
    }


}

function App() {
    return (
        <div className="App">
            <div className="main">
                <Table/>
            </div>

        </div>
    );
}

export default App;
